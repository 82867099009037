import React, { useState } from 'react';
import styled from 'styled-components';
import { FaRegCopy } from 'react-icons/fa';


const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 0.3rem;
  color: #d1d5db; /* Light gray color */
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;


const Container = styled.div`
  background-color: #111827;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const Title = styled.h1`
  margin-bottom: 2rem;
`;

const Form = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  margin-bottom: 1rem;
  width: 100%;
`;

const CommandContainer = styled.div`
  width: 100%; /* Use a percentage width for responsiveness */
  max-width: 600px; /* Adjust as needed */
  margin-bottom: 1rem;
  position: relative;
`;

const CommandTitle = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: yellow;
`;

const Command = styled.div`
  background-color: #1F2937;
  padding: 1rem;
  border-radius: 4px;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow-x: auto; /* Add horizontal scroll */
  min-height: 4rem;
  position: relative; /* Ensure the CopyButton is positioned within this container */
  &:hover button, &:focus-within button {
    opacity: 1; /* Show button on hover or focus */
  }
`;

const CopyButton = styled.button`
  background-color: transparent;
  color: #3F83F8; /* Icon color */
  border: none; /* No border */
  border-radius: 2px;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.3rem; /* Adjust icon size */
  position: absolute; /* Absolute positioning */
  top: 0.5rem; /* Adjust as needed */
  right: 0.5rem; /* Adjust as needed */
  z-index: 10; /* Ensure it stays above other content */
  opacity: 0; /* Make button transparent by default */
  transition: opacity 0.3s; /* Smooth transition */
  
  &:hover {
    color: white; /* Icon color on hover */
    opacity: 1; /* Ensure button is visible when hovered */
  }
`;


const Notification = styled.div`
  position: sticky;
  background-color: #3F83F8;
  color: white;
  border-radius: 2px;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.3rem; /* Adjust icon size */
  position: absolute; /* Absolute positioning */
  top: 0.5rem; /* Adjust as needed */
  right: 0.5rem; /* Adjust as needed */
  z-index: 10; /* Ensure it stays above other content */
  opacity: 0; /* Make button transparent by default */
  transition: opacity 0.3s; /* Smooth transition */
  font-size: 0.875rem; /* Smaller font size */
  opacity: ${props => (props.show ? 2 : 0)};
  transition: opacity 0.5s;
`;

const NginxConfiguration = () => {
  const [nodename, setNodename] = useState('');
  const [binary, setBinary] = useState('');
  const [servicename, setServicename] = useState('');
  const [port1, setApiport] = useState('');
  const [port2, setRpcport] = useState('');
  const [valoper, setValoper] = useState('');
  const [chainid, setChainid] = useState('');
  const [rpc, setRpc] = useState('');
  const [rpc2, setRpc2] = useState('');
  const [copied, setCopied] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const handleNodenameChange = (e) => setNodename(e.target.value);
  const handleBinaryChange = (e) => setBinary(e.target.value);
  const handleServicenameChange = (e) => setServicename(e.target.value);
  const handlePortApiChange = (e) => setApiport(e.target.value);
  const handlePortRpcChange = (e) => setRpcport(e.target.value);
  const handleValoper = (e) => setValoper(e.target.value);
  const handleChainid = (e) => setChainid(e.target.value);
  const handleRpc = (e) => setRpc(e.target.value);
  const handleRpc2 = (e) => setRpc2(e.target.value);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(text);
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 1100); // Hide notification after 1 second
    });
  };

  const commands = [
    {
      title: 'script',
      command: `sudo tee ./chains.d/${nodename}.yml > /dev/null << EOF
    chain_id: ${chainid}                                                  
    valoper_address: ${valoper}       
    public_fallback: yes

    alerts:
      stalled_enabled: yes
      stalled_minutes: 1
      consecutive_enabled: yes
      consecutive_missed: 5
      consecutive_priority: critical
      percentage_enabled: no
      percentage_missed: 5
      percentage_priority: warning
      alert_if_inactive: yes
      alert_if_no_servers: yes

      # Telegram settings
      telegram:
        enabled: yes                                              
        api_key: "7259969768:AAG7-HYbJZN5gPxK6hOUYzfLXjPIV9fUaDg"              # BOT TOKEN, Leave it if you wont configure it
        channel: "1109036120"              # Channel ID

    nodes:
      - url: ${rpc}:443
        alert_if_down: no
      - url: ${rpc2}:443
        alert_if_down: no

EOF`,
    },
    {
      title: 'script',
      command: `https://github.com/sychonix/script/blob/main/script.sh`,
    },
    {
      title: 'API config true',
      command: `sudo nano $HOME/${binary}/config/app.toml`,
    },
      {
      title: '',
      command: `sudo systemctl restart ${servicename}`,
    },    
    {
      title: '',
      command: `sudo nano /etc/nginx/sites-enabled/api-${nodename}-t.sychonix.com.conf`,
    },
    {
      title: '',
      command: `server {
    server_name api-${nodename}-t.sychonix.com;
    listen 80;
    location / {
        add_header Access-Control-Allow-Origin *;
        add_header Access-Control-Max-Age 3600;
        add_header Access-Control-Expose-Headers Content-Length;

        proxy_set_header   X-Real-IP        $remote_addr;
        proxy_set_header   X-Forwarded-For  $proxy_add_x_forwarded_for;
        proxy_set_header   Host             $host;

        proxy_pass http://0.0.0.0:${port1};
    }
}`,
    },
    {
      title: 'RPC config',
      command: `sudo nano $HOME/${binary}/config/config.toml`,
    },
    {
      title: '',
      command: `sudo nano /etc/nginx/sites-enabled/rpc-${nodename}-t.sychonix.com.conf`,
    },
    {
      title: '',
      command: `server {
    server_name rpc-${nodename}-t.sychonix.com;
    listen 80;
    location / {
        add_header Access-Control-Allow-Origin *;
        add_header Access-Control-Max-Age 3600;
        add_header Access-Control-Expose-Headers Content-Length;

        proxy_set_header   X-Real-IP        $remote_addr;
        proxy_set_header   X-Forwarded-For  $proxy_add_x_forwarded_for;
        proxy_set_header   Host             $host;

        proxy_pass http://127.0.0.1:${port2};
    }
}`,
    },
    {
      title: 'Create Snapshot',
      command: `sudo mkdir -p /var/www/html/snapshot/${nodename}/`,
    },
    {
      title: '',
      command: `sudo systemctl stop ${servicename}`,
    },
    {
      title: '',
      command: `cd $HOME/${binary}`,
    },
    {
      title: '',
      command: `sudo bash -c 'tar -cf - data | lz4 > /var/www/html/snapshot/${nodename}/${nodename}-latest.tar.lz4'`,
    },
    {
      title: '',
      command: `sudo systemctl start ${servicename}`,
    },
    {
      title: '',
      command: `nano /etc/nginx/sites-enabled/snapshot.sychonix.conf`,
    },
    {
      title: '',
      command: `server {
    listen 80;
    server_name snapshot.sychonix.com;

    location / {
        autoindex on;
        autoindex_exact_size off;
        autoindex_format html;
        autoindex_localtime on;

        root /var/www/html/snapshot/;
        index index.html index.htm;
    }

    # Optional: Additional configuration
    error_page 404 /404.html;
    location = /404.html {
        internal;
    }
}`,
    },
    {
      title: '',
      command: `sudo pkill nginx`,
    },
    {
      title: '',
      command: `sudo nginx -t`,
    },
    {
      title: '',
      command: `sudo certbot --nginx --register-unsafely-without-email`,
    },
    {
      title: '',
      command: `sudo certbot --nginx --redirect`,
    },
    {
      title: 'Create Snapshot Script',
      command: `cd snapshot`,
    },
    {
      title: '',
      command: `sudo tee $HOME/snapshot/${nodename}.sh > /dev/null << 'EOF'
sudo systemctl stop ${servicename}
cd $HOME/${binary}/
sudo rm /var/www/html/snapshot/${nodename}/${nodename}-latest.tar.lz4
sudo bash -c 'tar -cf - data | lz4 > /var/www/html/snapshot/${nodename}/${nodename}-latest.tar.lz4'
sudo systemctl start ${servicename}
EOF`,
    },
    {
      title: '',
      command: `crontab -l > cronjob`,
    },
    {
      title: '',
      command: `sudo chmod +x $HOME/snapshot/${nodename}.sh`,
    },
    {
      title: '',
      command: `echo "0 0 * * * $HOME/snapshot/${nodename}.sh" >> cronjob`,
    },
    {
      title: '',
      command: `crontab cronjob`,
    },
    {
      title: '',
      command: `rm cronjob`,
    },
    {
      title: '',
      command: `crontab -l`,
    },
  ];

  return (
    <Container>
      <Title>Configuration Management</Title>
      <Form>
        <FormItem>
          <Label>Node Name</Label>
          <Input
            type="text"
            value={nodename}
            onChange={handleNodenameChange}
            placeholder="Enter node name"
          />
        </FormItem>
        <FormItem>
          <Label>Binary Name</Label>
          <Input
            type="text"
            value={binary}
            onChange={handleBinaryChange}
            placeholder="Enter binary name"
          />
        </FormItem>
        <FormItem>
          <Label>Service Name</Label>
          <Input
            type="text"
            value={servicename}
            onChange={handleServicenameChange}
            placeholder="Enter service name"
          />
        </FormItem>
        <FormItem>
          <Label>API Port</Label>
          <Input
            type="text"
            value={port1}
            onChange={handlePortApiChange}
            placeholder="Enter Port for API"
          />
        </FormItem>
        <FormItem>
          <Label>RPC Port</Label>
          <Input
            type="text"
            value={port2}
            onChange={handlePortRpcChange}
            placeholder="Enter Port for RPC"
          />
        </FormItem>
        <FormItem>
          <Label>Valoper Address</Label>
          <Input
            type="text"
            value={valoper}
            onChange={handleValoper}
            placeholder="Enter Valoper Address"
          />
        </FormItem>
        <FormItem>
          <Label>Chain ID</Label>
          <Input
            type="text"
            value={chainid}
            onChange={handleChainid}
            placeholder="Enter Chain ID"
          />
        </FormItem>
        <FormItem>
          <Label>RPC URL 1</Label>
          <Input
            type="text"
            value={rpc}
            onChange={handleRpc}
            placeholder="Enter URL for RPC 1"
          />
        </FormItem>
        <FormItem>
          <Label>RPC URL 2</Label>
          <Input
            type="text"
            value={rpc2}
            onChange={handleRpc2}
            placeholder="Enter URL for RPC 2"
          />
        </FormItem>
      </Form>
      {commands.map((cmd, index) => (
        <CommandContainer key={index}>
          <CommandTitle>{cmd.title}</CommandTitle>
          <Command>
            <CopyButton onClick={() => handleCopy(cmd.command)}>
              {copied === cmd.command && <Notification show={showNotification}>Copied!</Notification>}
              <FaRegCopy />
            </CopyButton>
            <pre>{cmd.command}</pre>
          </Command>
        </CommandContainer>
      ))}
    </Container>
  );
  };
  
  export default NginxConfiguration;