import React, { useState } from 'react';
import styled from 'styled-components';
import { FaRegCopy } from 'react-icons/fa';

const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 0.3rem;
  color: #d1d5db; /* Light gray color */
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Container = styled.div`
  background-color: #111827;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const Title = styled.h1`
  margin-bottom: 2rem;
`;

const Form = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  margin-bottom: 1rem;
  width: 100%;
`;

const CommandContainer = styled.div`
  width: 100%; /* Use a percentage width for responsiveness */
  max-width: 600px; /* Adjust as needed */
  margin-bottom: 1rem;
  position: relative;
`;

const CommandTitle = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: yellow;
`;

const Command = styled.div`
  background-color: #1F2937;
  padding: 1rem;
  border-radius: 4px;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow-x: auto; /* Add horizontal scroll */
  min-height: 4rem;
  position: relative; /* Ensure the CopyButton is positioned within this container */
  &:hover button, &:focus-within button {
    opacity: 1; /* Show button on hover or focus */
  }
`;

const CopyButton = styled.button`
  background-color: transparent;
  color: #3F83F8; /* Icon color */
  border: none; /* No border */
  border-radius: 2px;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.3rem; /* Adjust icon size */
  position: absolute; /* Absolute positioning */
  top: 0.5rem; /* Adjust as needed */
  right: 0.5rem; /* Adjust as needed */
  z-index: 10; /* Ensure it stays above other content */
  opacity: 0; /* Make button transparent by default */
  transition: opacity 0.3s; /* Smooth transition */
  
  &:hover {
    color: white; /* Icon color on hover */
    opacity: 1; /* Ensure button is visible when hovered */
  }
`;

const Notification = styled.div`
  position: sticky;
  background-color: #3F83F8;
  color: white;
  border-radius: 2px;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.3rem; /* Adjust icon size */
  position: absolute; /* Absolute positioning */
  top: 0.5rem; /* Adjust as needed */
  right: 0.5rem; /* Adjust as needed */
  z-index: 10; /* Ensure it stays above other content */
  opacity: 0; /* Make button transparent by default */
  transition: opacity 0.3s; /* Smooth transition */
  font-size: 0.875rem; /* Smaller font size */
  opacity: ${props => (props.show ? 2 : 0)};
  transition: opacity 0.5s;
`;

const UsefulCommands = () => {
  const [nodename, setNodename] = useState('');
  const [binary, setBinary] = useState('');
  const [servicename, setServicename] = useState('');
  const [user, setUser] = useState('');
  const [copied, setCopied] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const handleNodenameChange = (e) => setNodename(e.target.value);
  const handleBinaryChange = (e) => setBinary(e.target.value);
  const handleServicenameChange = (e) => setServicename(e.target.value);
  const handleUserChange = (e) => setUser(e.target.value);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(text);
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 1100); // Hide notification after 1 second
    });
  };

  const commands = [
    {
      title: 'In VPS Service',
      command: `sudo systemctl stop ${servicename}`,
    },
    {
      title: 'Change Moniker',
      command: `sed -i 's/^moniker = "sychonix"/moniker = "sychonix-service"/' $HOME/.${binary}/config/config.toml`,
    },
    {
      title: 'Deleted File Privkey & Nodekey',
      command: `sudo systemctl stop ${servicename} && sudo rm -v $HOME/.${binary}/config/node_key.json $HOME/.${binary}/config/priv_validator_key.json && ls -a $HOME/.${binary}/config/`,
    },
    {
      title: '',
      command: `sudo systemctl restart ${servicename}`,
    },
    {
      title: '',
      command: `echo "0 */6 * * * /home/${nodename}/snapshot/${nodename}.sh" | crontab -`,
    },
    {
      title: '',
      command: `sudo systemctl restart ${servicename} && sudo journalctl -u ${servicename} -f -o cat`,
    },
    {
      title: '',
      command: `./snapshot/${nodename}.sh`,
    },
    {
      title: 'Set Seeds mode',
      command: `sed -i 's/seed_mode = true/seed_mode = false/' $HOME/.${binary}/config/config.toml`,
    },
    {
      title: 'Get Seeds Peers',
      command: `echo $(${servicename} tendermint show-node-id)'@'${nodename}-testnet.sychonix.com':'$(cat $HOME/.${binary}/config/config.toml | sed -n '/Address to listen for incoming connection/{n;p;}' | sed 's/.*://; s/".*//')`,
    },

    {
      title: '1. Move the node_key.json file to the config folder',
      command: `sudo mv /home/sychonix/node_key.json /home/${nodename}/.${binary}/config/`,
    },
    {
      title: '2. Move the priv_validator_key.json file to the config folder',
      command: `sudo mv /home/sychonix/priv_validator_key.json /home/${nodename}/.${binary}/config/`,
    },
    {
      title: '3. Restart the service',
      command: `sudo systemctl restart ${servicename}`,
    },
    {
      title: '- Backup file to save in SFTP folder via sychonix user',
      command: `sudo mv /home/sychonix/node_key.json /home/${nodename}/.${binary}/config/`,
    },
    {
      title: '1. Move the priv_validator_key.json file to the config folder',
      command: `sudo cp /home/${nodename}/.${binary}/config/node_key.json /home/sychonix/`,
    },
    {
      title: '2. Move the priv_validator_key.json file to the config folder',
      command: `sudo cp /home/${nodename}/.${binary}/config/priv_validator_key.json /home/sychonix/`,
    },
    {
      title: '3. Change file ownership to sychonix user and start backup via SFTP',
      command: `sudo chown sychonix:sychonix /home/sychonix/node_key.json /home/sychonix/priv_validator_key.json`,
    },
    {
      title: '4. After successful backup via SFTP delete the file in the sychonix user',
      command: `sudo rm -rf node_key.json priv_validator_key.json`,
    },
    {
      title: '',
      command: 'sudo lsof -i -P -n | grep LISTEN',
    },
    {
      title: 'Check Logs',
      command: `sudo journalctl -u ${servicename} -f --no-hostname -o cat`,
    },
    {
      title: 'Check Balance',
      command: `${servicename} q bank balances $(${servicename} keys show wallet -a)`,
    },
    {
      title: 'Disk',
      command: 'wget -qO- bench.sh | bash',
    },
    {
      title: 'Find File',
      command: `sudo find / -name ${nodename}`,
    },
    {
      title: 'Get Sync Info',
      command: `${servicename} status 2>&1 | jq -r '.SyncInfo.catching_up // .sync_info.catching_up'`,
    },
    {
      title: '',
      command: `${servicename} status 2>&1 | jq -r '.SyncInfo.latest_block_height // .sync_info.latest_block_height'`,
    },
    {
      title: 'Index null',
      command: `sed -i -e 's|^indexer *=.*|indexer = "null"|' $HOME/${binary}/config/config.toml`,
    },
  ];

  return (
    <Container>
      <Title>Configuration Management</Title>
      <Form>
        <FormItem>
          <Label>Node Name</Label>
          <Input
            type="text"
            value={nodename}
            onChange={handleNodenameChange}
            placeholder="Enter node name"
          />
        </FormItem>
        <FormItem>
          <Label>Binary Name</Label>
          <Input
            type="text"
            value={binary}
            onChange={handleBinaryChange}
            placeholder="Enter binary name"
          />
        </FormItem>
        <FormItem>
          <Label>Service Name</Label>
          <Input
            type="text"
            value={servicename}
            onChange={handleServicenameChange}
            placeholder="Enter service name"
          />
        </FormItem>
        <FormItem>
          <Label>User Name</Label>
          <Input
            type="text"
            value={user}
            onChange={handleUserChange}
            placeholder="Enter user name"
          />
        </FormItem>
      </Form>
      {commands.map((cmd, index) => (
        <CommandContainer key={index}>
          <CommandTitle>{cmd.title}</CommandTitle>
          <Command>
            <CopyButton onClick={() => handleCopy(cmd.command)}>
              {copied === cmd.command && <Notification show={showNotification}>Copied!</Notification>}
              <FaRegCopy />
            </CopyButton>
            <pre>{cmd.command}</pre>
          </Command>
        </CommandContainer>
      ))}
    </Container>
  );
  };
  
  export default UsefulCommands;