// App.js
import React, { useState } from 'react';
import styled from 'styled-components';
import NginxConfiguration from './NginxConfiguration'; // Adjust the import path as necessary
import UsefulCommands from './UsefulCommands'; // Adjust the import path as necessary
import InstallCofiguration from './install'; // Adjust the import path as necessary
import CosmovisorSetup from './cosmovisorsetup'; // Adjust the import path as necessary

const Container = styled.div`
  background-color: #111827;
  color: white;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const Title = styled.h1`
  margin-bottom: 2rem;
`;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  width: 100%; /* Ensures the wrapper takes up the full width */
`;

const Tabs = styled.div`
  display: flex;
`;

const TabButton = styled.button`
  background-color: ${({ active }) => (active ? '#1A56DB40' : '#1C64F2')};
  color: white;
  border: none;
  padding: 0.75rem 1.5rem; 
  cursor: pointer;
  margin: 0 0.5rem;
  border-radius: 4px;

  &:hover {
    background-color: ${({ active }) => (active ? '#1A56DB40' : '#1C64F2')};
  }
`;

const App = () => {
  const [selectedTab, setSelectedTab] = useState('cosmovisorsetup'); // Default tab

  return (
    <Container>
      <Title></Title>
      <TabsWrapper>
      <Tabs>
        <TabButton active={selectedTab === 'cosmovisorsetup'} onClick={() => setSelectedTab('cosmovisorsetup')}>
          cosmovisor setup
        </TabButton>
        <TabButton active={selectedTab === 'useful'} onClick={() => setSelectedTab('useful')}>
          Useful Commands
        </TabButton>
        <TabButton active={selectedTab === 'nginx'} onClick={() => setSelectedTab('nginx')}>
          Nginx Configuration
        </TabButton>
        <TabButton active={selectedTab === 'install'} onClick={() => setSelectedTab('install')}>
          Install Configuration
        </TabButton>
        </Tabs>
      </TabsWrapper>
      {selectedTab === 'cosmovisorsetup' && <CosmovisorSetup/>}
      {selectedTab === 'nginx' && <NginxConfiguration />}
      {selectedTab === 'install' && <InstallCofiguration/>}
      {selectedTab === 'useful' && <UsefulCommands/>}
    </Container>
  );
};

export default App;
